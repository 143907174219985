<template>
	<div>
		<ArticlesComponent :articles='articles' :loading='loading'></ArticlesComponent>		
	</div>
</template>

<script type="text/javascript">

import ArticlesComponent from "@/components/ArticlesComponent.vue"

export default {
	name: 'Articles',
	components: {
		ArticlesComponent,
	},
	computed: {
		//articles() { return this.$store.getters.getArticles },
		articles() { return this.$store.getters.getArticles.filter(item=>item.news==true) },
		loading()  { return this.$store.getters.getArticlesLoading },
	},
	beforeMount(){
		//this.getArticles()
		this.$store.dispatch('uploadArticlesAsync')
		
	}
}
</script>